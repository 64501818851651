<template>
  <div class="white_bg border_radius mb-3" @click="finder">
    <div class="row align-items-center p-4">
      <div class="col-auto">
        <IconRound size="big" align="center" >
          <IconSearchUser color="gold" size="size28"></IconSearchUser>
        </IconRound>
      </div>
      <div class="col">
        <div class="green-text-dark medium mb-2">
          Already Donated? Find your Donor Profile Details <span><IconArrowForward color="darkgreen" size="size16" inline="inline" /></span>
        </div>
        <div class="font12">
          Click here to search if you're registered as a donor and retrieve your login details
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { login } from '@/api/user'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    IconRound: defineAsyncComponent(() => import('@/components/IconRound.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    IconSearchUser: defineAsyncComponent(() => import('@/components/icons/IconSearchUser.vue'))
  },
  name: 'DonorFinderButton',
  methods: {
    finder () {
      this.$emit('finder')
    }
  }
}
</script>

<style scoped>
.border_radius {
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.04) 7px 7px 15px 0px;
  cursor: pointer;
}
</style>
